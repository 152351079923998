import { EmploymentIncomeStep } from '~/pages/rental-application/steps/EmploymentIncomeStep';

const EmploymentIncomePage = () => {
  return (
    <div class="relative flex h-full grow flex-col">
      <EmploymentIncomeStep />
    </div>
  );
};

export default EmploymentIncomePage;
