import { MagicUseCase } from '~/use-cases/magicUseCase';

export class RemoveEmploymentHistoryErrorUseCase extends MagicUseCase {
  protected async runLogic(index: string) {
    if (!index) {
      return
    }
    const employmentHistoryErrors = this.getState().user.rentalApplication.employmentHistoryErrors
    if (!employmentHistoryErrors) {
      return;
    }
    this.getState().user.rentalApplication.employmentHistoryErrors.splice(Number(index), 1);
  }
}
