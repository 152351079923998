import { Component, Show } from 'solid-js';
import { COMPANY_NAME, EMPLOYMENT_HISTORY, END_DATE, PHONE, POSITION, REMOVE, SALARY, START_DATE } from '~/assets/strings';
import LabeledTextInput from '~/components/common/Inputs/LabeledTextInput';
import useUseCase from '~/framework/hooks/useUseCase';
import { useLocalization } from '~/hooks/useLocalization';
import { PresentableEmploymentHistoryError, PresentableEmploymentInformation } from '~/presenters/RentalApplicationPresenter';
import { ValidateRentalApplicationInfoUseCase } from '~/use-cases/rental-applications/application-steps/validateRentalApplicationInfoUseCase';

export interface RentalHistoryItemProps {
  index: number;
  history: PresentableEmploymentInformation;
  errors?: PresentableEmploymentHistoryError;
  onUpdate: (item: PresentableEmploymentInformation, index: number) => void;
  onDelete: (index: number) => void;
}

export const EmploymentHistoryItem: Component<RentalHistoryItemProps> = (props) => {
  const { t } = useLocalization();
  const { execute: validateRentalApplication } = useUseCase(ValidateRentalApplicationInfoUseCase);


  let nameRef: HTMLInputElement | undefined;
  let phoneRef: HTMLInputElement | undefined;
  let positionRef: HTMLInputElement | undefined;
  let salaryRef: HTMLInputElement | undefined;
  let startDateRef: HTMLInputElement | undefined;
  let endDateRef: HTMLInputElement | undefined;

  const onUpdate = (fieldName: string) => {
    const history: PresentableEmploymentInformation = {
      name: nameRef?.value || '',
      phone: phoneRef?.value || '',
      position: positionRef?.value || '',
      salary: salaryRef?.value,
      startDate: startDateRef?.value,
      endDate: endDateRef?.value,
    };
    props.onUpdate(history, props.index);
    validateRentalApplication(fieldName)
  };

  return (
    <div class="grid w-full grid-cols-2 gap-5 pb-10">
      <div class="text-md font-semibold text-text-level01">
        {t(EMPLOYMENT_HISTORY)} {props.index + 1}
        <Show when={props.index === 0}>
          <span class="text-error"> *</span>
        </Show>
      </div>
      <div class="flex items-end justify-end">
        <button class="rounded-md border border-danger bg-danger-light px-2 text-danger" onClick={() => props.onDelete(props.index)}>
          {t(REMOVE)}
        </button>
      </div>
      <div class="col-span-2 grid grid-cols-2 gap-5">
        <LabeledTextInput
          ref={nameRef}
          label={t(COMPANY_NAME)}
          value={props.history.name}
          error={props.errors?.employmentInformationNameError}
          onChange={() => {
            onUpdate(`employment.${props.index}.name`);
          }}
          required
        />
        <LabeledTextInput
          ref={phoneRef}
          label={t(PHONE)}
          value={props.history.phone}
          error={props.errors?.employmentInformationPhoneError}
          onChange={() => {
            onUpdate(`employment.${props.index}.phone`);
          }}
        />
        <LabeledTextInput
          ref={positionRef}
          label={t(POSITION)}
          value={props.history.position}
          error={props.errors?.employmentInformationPositionError}
          onChange={() => {
            onUpdate(`employment.${props.index}.position`);
          }}
          required
        />
        <LabeledTextInput 
          ref={salaryRef} 
          label={t(SALARY)} 
          value={props.history.salary || ''} 
          error={props.errors?.employmentInformationSalaryError}
          onChange={() => {
            onUpdate(`employment.${props.index}.salary`);
          }}
        />
        <LabeledTextInput
          ref={startDateRef}
          label={t(START_DATE)}
          type="date"
          value={props.history.startDate}
          error={props.errors?.employmentInformationStartDateError}
          onChange={() => {
            onUpdate(`employment.${props.index}.startDate`);
            onUpdate(`employment.${props.index}.endDate`);
          }}
          required
        />
        <LabeledTextInput
          ref={endDateRef}
          label={t(END_DATE)}
          type="date"
          value={props.history.endDate}
          error={props.errors?.employmentInformationEndDateError}
          onChange={() => {
            onUpdate(`employment.${props.index}.endDate`);
          }}
        />
      </div>
    </div>
  );
};
